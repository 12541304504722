import { atomWithStorage } from "jotai/utils";

export const isUpdatingAtom = atomWithStorage("isUpdating", false);

export const formdataAtom = atomWithStorage("formdata", {
  firstname: "",
  lastname: "",
  middlename: "",
  gender: "",
  country: "",
  city: "",
  email: "",
  email_verified_at: "",
  phone: "",
  whatsapp_phone: "",
  referral_source: "",
  referral_detail: "",
  communication_consent: true,
  agreement: false,
  agreement1: false,
  agreement2: false,
  referrer: "",
  openness_to_tools: "",
  work_disruption_feelings: "",
  feedback_handling: "",
  approach_to_new_tasks: "",
  education_focus: "",
  highest_qualification: "",
  pdf: null,

  employment_status: "",
  unemployment_duration: "",
  reason_for_leaving: "",
  exit_timeline: "",
  service_completion_date: "",
  work_experience_years: "",
  job_urgency: 0,

  key_challenge: "",
  leadership_experience: "",
  distractions: "",
  bootcamp_expectations: "",

  anotherJobText: "",
  anotherJobText1: "",
  whyParticipate: "",
  typeOfPhone: "",
  haveLaptop: "",
  parentAlive: "",
  employed: "",
  affordableFee: "",
  whyBootcamp: "",
  anythingElse: "",
  paymentOption: "",
  eligibilityStatus: "not measured",
  videoProgress: 0,
  isVideoEnded: false,
  attemptCounter: 0,
});

export const cohortAtom = atomWithStorage("cohort", {
  id: "",
  name: "",
});

export const sharedErrorsAtom = atomWithStorage("sharedErrors", []);

export const hasUploadedCVAtom = atomWithStorage("hasUploadedCV", false);

export const progressAtom = atomWithStorage("progress", 1);

export const referrerVendorAtom = atomWithStorage(
  "referralVendor",
  "naomi-lucas"
);

export const percentageAtom = atomWithStorage(
  "percentage",
  (get) => get(progressAtom) * 16.67
);

export const isLoggedInAtom = atomWithStorage("isLoggedIn", false);

export const selectedDateAtom = atomWithStorage("selectedDate", {
  startDate: null,
  endDate: null,
});
