import {
  readinessValues,
  adaptabilityValues,
  feedbackValues,
  growthValues,
} from "../../constants/eligibilityConstants";

export const calculateOverallEligibility = ({
  highest_qualification,
  openness_to_tools,
  work_disruption_feelings,
  feedback_handling,
  approach_to_new_tasks,
}) => {
  const scores = {
    highest_qualification:
      highest_qualification === "I don’t have a post-secondary qualification"
        ? 4
        : 1,
    openness_to_tools:
      readinessValues.findIndex((item) => item.value === openness_to_tools) + 1,
    work_disruption_feelings:
      adaptabilityValues.findIndex(
        (item) => item.value === work_disruption_feelings
      ) + 1,
    feedback_handling:
      feedbackValues.findIndex((item) => item.value === feedback_handling) + 1,
    approach_to_new_tasks:
      growthValues.findIndex((item) => item.value === approach_to_new_tasks) +
      1,
  };

  const ineligibleCount = Object.values(scores).filter(
    (score) => score >= 4
  ).length;

  if (ineligibleCount >= 3) {
    return "ineligible";
  } else if (ineligibleCount === 2 || scores.highest_qualification === 4) {
    return "action_required";
  } else {
    return "eligible";
  }
};
