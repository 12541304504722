import React from "react";
import { useNavigate } from "react-router-dom";

const Success = () => {
  const router = useNavigate();

  const continueToVideo = () => {
    router('/apply/selection-video')
  }

  return (
    <div className="col-md-8 mt-5 slide-col text-center">
      <div className="alert alert-success fw-bold">
        Well done for submitting your application. 
        <br />
        You have one more step to take to confirm your selection for the bootcamp.
        <br />
        In this final phase, you are required to watch a short video by the Program Director and take a few short quizzes in-between.
        <br />
        Please proceed by clicking the button below.
      </div>
      <button
        type="button"
        className="btn btn-primary float-end"
        onClick={continueToVideo}
        style={{
          backgroundColor: "#ECA239",
          color: "black",
          border: "1px solid #ECA239",
        }}
      >
        Continue to Video
      </button>
    </div>
  );
};

export default Success;
