import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { useAtom } from "jotai";
import { formdataAtom, selectedDateAtom } from "../../lib/atom";
import PaymentOption from "./paymentOptionLocal";
import PaymentOptionInter from "./paymentOptionInter";

const SelectionConfirmation = () => {
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [formdata] = useAtom(formdataAtom);
  const [, setSelectedDate] = useAtom(selectedDateAtom);
  const [showPaymentOption, setShowPaymentOption] = useState(false);
  const [isInternational, setIsInternational] = useState(false);

  const handlePaymentFailure = (message) => {
    setIsLoading(false);
    MySwal.fire(
      "Error",
      message || "Payment failed, please try again.",
      "error"
    );
    navigate("/failed");
  };

  const handlePayment = async () => {
    setIsLoading(true);
  
    try {
      let userCountry;
  
      // First, try to fetch the user's country using the ipgeolocation.io API
      try {
        const geolocationResponse = await fetch(
          "https://api.ipgeolocation.io/ipgeo?apiKey=042ee85d77d74e1c890e012cd76c8c55"
        );
        
        if (geolocationResponse.status === 429 || geolocationResponse.status === 403) {
          throw new Error("API limit reached");
        }
  
        const geolocationData = await geolocationResponse.json();
        userCountry = geolocationData.country_name.toLowerCase();
      } catch (error) {
        console.warn("Geolocation API failed, using formdata country:", error);
        const { country } = formdata;
        userCountry = country.trim().toLowerCase();
      }
  
      // Set the appropriate payment option based on user's country
      if (userCountry === "nigeria") {
        setIsInternational(false);
      } else {
        setIsInternational(true);
      }
      setShowPaymentOption(true);
    } catch (error) {
      console.error("Payment failed:", error);
      handlePaymentFailure("Payment failed due to an unexpected error.");
    } finally {
      setIsLoading(false);
    }
  };  

  const submitReminder = async () => {
    if (!startDate) {
      MySwal.fire("Error", "Please select a date and time", "error");
      return;
    }

    const fd = new FormData();
    setIsLoading(true);
    fd.append("email", formdata.email);
    fd.append("reminderDate", startDate.toISOString());

    const apiUrl = `${process.env.REACT_APP_BASE_URL}/update/${formdata.email}`;

    try {
      await axios.post(apiUrl, fd);
      setSelectedDate({ reminderDate: startDate });
      localStorage.setItem("reminderSet", "true");

      MySwal.fire("Success", "Reminder has been set!", "success").then(() => {
        setShowDatePicker(false);
      });
    } catch (error) {
      console.error("Failed to set reminder:", error);
      setIsLoading(false);
      MySwal.fire("Error", "Failed to set reminder", "error");
    } finally {
      setIsLoading(false);
    }
  };

  if (showPaymentOption) {
    return isInternational ? <PaymentOptionInter /> : <PaymentOption />;
  }

  return (
    <div className="col-md-8 mt-5 slide-col text-center">
      {showDatePicker ? (
        <>
          <div>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date || new Date())}
              inline
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              dateFormat="MMMM d, yyyy h:mm aa"
              monthsShown={1}
              className="custom-datepicker"
            />
          </div>
          <div className="pt-4">
            <button
              type="button"
              onClick={submitReminder}
              className="btn btn-primary float-end"
              style={{
                backgroundColor: "#ECA239",
                color: "black",
                border: "1px solid #ECA239",
              }}
              disabled={isLoading}
            >
              {isLoading ? (
                <div className="spinner-border spinner-border-sm" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                "Set Reminder"
              )}
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="alert alert-success fw-bold">
            Congratulations. 
            <br />
            You have been accepted into the next cohort of The Employment Bootcamp. 
            <br />
            To secure your spot in the program, you are expected to pay a commitment fee. 
            <br />
            Please click the button below to view all payment options available to you. 
            <br />
            Thank you.
          </div>
          <div className="float-end pt-5">
            <button
              type="button"
              onClick={handlePayment}
              className="btn btn-primary"
              style={{
                backgroundColor: "#ECA239",
                color: "black",
                border: "1px solid #ECA239",
              }}
              disabled={isLoading}
            >
              {isLoading ? (
                <div className="spinner-border spinner-border-sm" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                "Proceed to payment"
              )}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default SelectionConfirmation;