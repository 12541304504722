import React, { useEffect, useState } from "react";
import TextInputField from "../Form/TextInputField";
import { useAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import {
  isUpdatingAtom,
  progressAtom,
  formdataAtom,
  cohortAtom,
} from "../../lib/atom";
import { useFormValidation } from "../../hooks/useFormValidation";
import { useSaveEmailToDatabase } from "../../hooks/useSaveEmailToDatabase";
import SaveAndContinueButton from "../Utility/SaveAndContinueButton";
import axios from "axios";

const Login = () => {
  const router = useNavigate();
  const [formdata] = useAtom(formdataAtom);
  const { validate } = useFormValidation("login");
  const { saveEmailToDatabase } = useSaveEmailToDatabase();
  const [errors, setErrors] = useState([]);
  const [isUpdate] = useAtom(isUpdatingAtom);
  const [progress, setProgress] = useAtom(progressAtom);
  const [, setCohort] = useAtom(cohortAtom);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const getLatestCohort = async () => {
      const baseUrl = process.env.REACT_APP_BASE_URL;
      const endpoint = "get-current";
      const apiUrl = `${baseUrl}/${endpoint}`;
      try {
        setLoading(true);
        const response = await axios.get(apiUrl);
        setCohort({
          id: response.data.data[0].id,
          name: response.data.data[0].name,
        });
      } catch (err) {
        setErrors([err?.response?.data?.message || err.toString()]);
      } finally {
        setLoading(false);
      }
    };

    getLatestCohort();
    setProgress(1);
  }, [setProgress, setCohort]);

  const handleLogin = async () => {
    setErrors([]);
    const errs = (await validate()) || [];
    if (errs.length) {
      setErrors(errs);
      return;
    }

    try {
      await saveEmailToDatabase();
      setProgress(progress + 1);
    } catch (error) {
      setErrors([error.message || "Unable to process registration"]);
    }
  };

  return (
    <div className="col-md-8 slide-col" id="Login">
      <h4 className="text-start text-uppercase fw-bold">Get Started</h4>
      {isLoading ? (
        <h4>Loading...</h4>
      ) : (
        <form>
          <div className="mb-4">
            <TextInputField
              name="email"
              type="email"
              placeholder="jane@example.com"
              label="Enter your email address"
              value={formdata.email}
            />
          </div>
          {errors.map((error, index) => (
            <div key={index} className="alert alert-danger">
              {error}
            </div>
          ))}
          {<SaveAndContinueButton handleForm={handleLogin} />}
        </form>
      )}
    </div>
  );
};

export default Login;
