import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import {
  isUpdatingAtom,
  progressAtom,
  formdataAtom,
  cohortAtom,
} from "../../lib/atom";
import { useFormValidation } from "../../hooks/useFormValidation";
import { useSaveEmailToDatabase } from "../../hooks/useSaveEmailToDatabase";
import SaveAndContinueButton from "../Utility/SaveAndContinueButton";
import axios from "axios";

const VerifyEmail = () => {
  const router = useNavigate();
  const [formdata] = useAtom(formdataAtom);
  const { validate } = useFormValidation("login");
  const { saveEmailToDatabase } = useSaveEmailToDatabase();
  const [errors, setErrors] = useState([]);
  const [isUpdate] = useAtom(isUpdatingAtom);
  const [progress, setProgress] = useAtom(progressAtom);
  const [, setCohort] = useAtom(cohortAtom);
  const [isLoading, setLoading] = useState(false);
  const [otpToken, setOtpToken] = useState("");
  const [time, setTime] = useState(120);
  const [canResend, setCanresend] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    if (time <= 0) {
      setCanresend(true);
      return;
    }

    const intervalId = setInterval(() => {
      setTime((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [time]);

  const handleVerifyMail = async () => {
    setErrors([]);

    if (!otpToken) {
      setErrors((prev) => [...prev, "Please enter OTP"]);
      return;
    }

    try {
      const fd = {
        token: otpToken,
        email: formdata.email,
      };
      setLoading(true);
      const apiUrl = `${process.env.REACT_APP_BASE_URL}/verify-email`;
      await axios.post(apiUrl, fd);

      router("/apply/eligibility");
    } catch (error) {
      setErrors([
        error?.response?.data?.message ||
          error?.message ||
          "Unable to process registration",
      ]);
      setLoading(false);
    }
  };

  const handleResendToken = async (e) => {
    e.preventDefault();
    setErrors([]);

    try {
      const fd = {
        email: formdata.email,
      };
      setLoading(true);
      const apiUrl = `${process.env.REACT_APP_BASE_URL}/resend-token`;
      const res = await axios.post(apiUrl, fd);
      setSuccessMessage(res?.data?.message || "OTP sent successfully");
      setCanresend(false);
      setTime((prev) => prev * 2);
    } catch (error) {
      setErrors([
        error?.response?.data?.message ||
          error.message ||
          "Unable to process registration",
      ]);
    } finally {
      setLoading(false);
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds
    ).padStart(2, "0")}`;
  };

  return (
    <div className="col-md-8 slide-col" id="Login">
      <h4 className="text-start text-uppercase fw-bold">
        Verify your email address
      </h4>
      <p>Please enter the otp send to your email address {formdata.email}</p>
      <form>
        <div className="mb-4">
          <label
            htmlFor="OTP"
            className="form-label"
            style={{ color: "black", fontWeight: "bold" }}
          >
            Enter OTP
          </label>
          <input
            name="otp"
            type="text"
            className="form-control"
            placeholder="903483"
            label="Enter your email address"
            value={otpToken}
            onChange={(e) => setOtpToken(e.target.value)}
          />
        </div>
        <div className="mb-4">
          {canResend ? (
            <a className="text-end" onClick={handleResendToken} href="#">
              Resend OTP
            </a>
          ) : (
            <p className="text-end">You can resend OTP in {formatTime(time)}</p>
          )}
        </div>
        {errors.map((error, index) => (
          <div key={index} className="alert alert-danger">
            {error}
          </div>
        ))}
        {successMessage && (
          <div className="alert alert-success">{successMessage}</div>
        )}
        {
          <SaveAndContinueButton
            isLoading={isLoading}
            handleForm={handleVerifyMail}
          />
        }
      </form>
    </div>
  );
};

export default VerifyEmail;
