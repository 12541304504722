import { useAtom } from "jotai";
import { formdataAtom, isUpdatingAtom, sharedErrorsAtom } from "../lib/atom";
import Swal from "sweetalert2";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export const useUpdateApplication = () => {
  const router = useNavigate();
  const [formdata] = useAtom(formdataAtom);
  const [, setSharedErrors] = useAtom(sharedErrorsAtom);
  const [, setIsUpdate] = useAtom(isUpdatingAtom);

  const updateApplication = async () => {
    Swal.fire({
      title: "Confirm your email address",
      text: "You will not be able to change it after this. Continue ?",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#ECA239",
      cancelButtonColor: "#d2d2d7",
      confirmButtonText: "Yes, continue",
      cancelButtonText: "Edit",
      showLoaderOnConfirm: true,
      preConfirm: async (login) => {
        const fd = {
          firstname: formdata.firstname,
          middlename: formdata.middlename,
          lastname: formdata.lastname,
          gender: formdata.gender,
          country: formdata.country,
          city: formdata.city,
          email: formdata.email,
          phone: formdata.phone,
          whatsapp_phone: formdata.whatsapp_phone,
          referred_by: formdata.referrer,
          communications: {
            referral_source: formdata.referral_source,
            referral_detail: formdata.referral_detail,
            communication_consent:
              formdata.communication_consent == true ? "Yes" : "No",
          },
        };

        const apiUrl = process.env.REACT_APP_BASE_URL + "/application";

        try {
          await axios.post(apiUrl, fd);
          setIsUpdate(true);

          Swal.fire({
            title: "Verify your email",
            text: "An email OTP has been sent to your email address for verification",
            icon: "success",
            confirmButtonText: "Continue",
            confirmButtonColor: "#ECA239",
            preConfirm: async (login) => {
              router("/apply/verify-email");
            },
          });
        } catch (error) {
          console.log("ERRR::", error);

          const msg =
            error?.message ||
            error?.response?.data?.message ||
            "An error occured. Try again";

          setSharedErrors((prev) => [...prev, msg]);

          const errors = error?.response?.data?.data?.error;

          if (errors) {
            Object.keys(errors).forEach((key) => {
              const messages = errors[key]; // Array of messages
              messages.forEach((message) => {
                console.log(message);
                setSharedErrors((prev) => [...prev, message]);
              });
            });
          }
          return Swal.close();
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });

    // .then((result) => {
    //   if (result.isConfirmed) {
    //     Swal.fire({
    //       title: "Almost there!",
    //       text: "You are almost done with your application",
    //       icon: "success",
    //       confirmButtonText: "Continue",
    //       confirmButtonColor: "#ECA239",
    //       preConfirm: async (login) => {
    //         router("/apply/eligibility");
    //       },
    //     });
    //   }
    // });
  };

  return { updateApplication };
};
