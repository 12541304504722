import { useAtom, useSetAtom } from "jotai";
import {
  formdataAtom,
  progressAtom,
  cohortAtom,
  isUpdatingAtom,
  isLoggedInAtom,
  sharedErrorsAtom,
} from "../lib/atom";
import Swal from "sweetalert2";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export const useUpdateOther = () => {
  const router = useNavigate();
  const [formdata] = useAtom(formdataAtom);
  const resetFormdata = useSetAtom(formdataAtom);
  const resetProgress = useSetAtom(progressAtom);
  const resetCohort = useSetAtom(cohortAtom);
  const resetIsUpdating = useSetAtom(isUpdatingAtom);
  const resetIsLoggedIn = useSetAtom(isLoggedInAtom);
  const [, setSharedErrors] = useAtom(sharedErrorsAtom);

  const clearStorage = () => {
    // Reset all relevant atoms
    resetFormdata({});
    resetProgress(1);
    resetCohort({ id: "", name: "" });
    resetIsUpdating(false);
    resetIsLoggedIn(false);
    console.log("Local storage cleared!");
  };

  const updateOther = async () => {
    Swal.fire({
      title: "Please confirm your answers",
      text: "You will not be able to change it after this. Continue?",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#ECA239",
      cancelButtonColor: "#d2d2d7",
      confirmButtonText: "Yes, continue",
      cancelButtonText: "Edit",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        const fd = {
          email: formdata.email,
          other: {
            key_challenge: formdata.key_challenge,
            leadership_experience: formdata.leadership_experience,
            distractions: formdata.distractions,
            bootcamp_expectations: formdata.bootcamp_expectations,
          },
        };

        const apiUrl = process.env.REACT_APP_BASE_URL + `/application/other`;
        // const apiUrl = `https://credentials.naomilucas.xyz/api/bootcamp/update/${email}`;

        try {
          const res = await axios.post(apiUrl, fd);
          console.log("Registration complete", res);
          router("/apply/success");
          // Clear local storage after submission
          // clearStorage();
        } catch (error) {
          const msg =
            error?.message ||
            error?.response?.data?.message ||
            "An error occured. Try again";

          setSharedErrors((prev) => [...prev, msg]);

          const errors = error?.response?.data?.data?.error;

          if (errors) {
            Object.keys(errors).forEach((key) => {
              const messages = errors[key]; // Array of messages
              messages.forEach((message) => {
                console.log(message);
                setSharedErrors((prev) => [...prev, message]);
              });
            });
          }
          return Swal.close();
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });

    // .then((result) => {
    //   if (result.isConfirmed) {
    //     Swal.fire({
    //       title: "Almost there!",
    //       text: "You are almost done with your application",
    //       icon: "success",
    //       confirmButtonText: "Continue",
    //       confirmButtonColor: "#ECA239",
    //       preConfirm: async () => {
    //         router("/apply/success");
    //       },
    //     });
    //   }
    // });
  };

  return { updateOther };
};
