import React, { useEffect, useState } from "react";
import TextInputField from "../../Form/TextInputField";
import Radio from "../../Form/Radio";
import { progressAtom, formdataAtom } from "../../../lib/atom";
import { useAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import { radioItems } from "./constants/radioItems";
import { useFormValidation } from "../../../hooks/useFormValidation";
import SaveAndContinueButton from "../../Utility/SaveAndContinueButton";
import Select from "react-select";
import { countryOptions } from "./constants/countryOptions";

const BioData = () => {
  const router = useNavigate();
  const [formdata, setFormdata] = useAtom(formdataAtom);
  const [progress, setProgress] = useAtom(progressAtom);
  const [isLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const { validate } = useFormValidation("biodata");

  useEffect(() => {
    setProgress(2);
  }, [setProgress]);

  const handleForm = async () => {
    const errs = await validate();

    if (errs.length) {
      setErrors(errs);
      return;
    }

    setProgress(progress + 1);
    router("/apply/communications");
  };

  const handleCountryChange = (selectedOption) => {
    setFormdata((prev) => ({
      ...prev,
      country: selectedOption ? selectedOption.value : "",
    }));
  };

  return (
    <div className="col-md-8 slide-col" id="biodata">
      <div style={{ color: "black" }}>
        <p>
          The Employment Bootcamp is designed for a specific type of person. It
          is designed for people who want more for themselves and their careers.
          People who are tired of searching for a job without success, employed
          but unhappy, motivated but lacking opportunity, STUCK or frustrated.
          People who are determined to do what it takes to actualise the future
          they have envisioned for themselves.
        </p>
        <p>
          Kindly answer the questions below as truthfully and accurately as you
          can.
        </p>
        <p className="mb-5">Best wishes!</p>
      </div>
      <h4 className="text-start text-uppercase fw-bold" style={{ color: "black" }}>
        Bio Data
      </h4>
      <form>
        <div className="mb-4">
          <TextInputField
            name="firstname"
            label="First Name"
            placeholder="Femi"
            value={formdata.firstname}
          />
        </div>
        <div className="mb-4">
          <TextInputField
            name="middlename"
            label="Middle Name"
            placeholder="Made"
            value={formdata.middlename}
          />
        </div>
        <div className="mb-4">
          <TextInputField
            name="lastname"
            label="Last Name"
            placeholder="Kuti"
            value={formdata.lastname}
          />
        </div>
        <div className="mb-4">
          <span className="mb-2" style={{ fontWeight: "bold", color: "black" }}>
            Gender
          </span>
          <Radio items={radioItems} name="gender" value={formdata.gender} />
        </div>

        <div className="mb-4">
          <label style={{ fontWeight: "bold", color: "black" }}>
            Country of Origin
          </label>
          <Select
            options={countryOptions}
            isClearable
            isSearchable
            placeholder="Select your country"
            value={
              formdata.country
                ? { value: formdata.country, label: formdata.country }
                : null
            }
            onChange={handleCountryChange}
          />
        </div>

        <div className="mb-4">
          <TextInputField
            name="city"
            label="Country and City of Residence"
            placeholder="Lagos, Nigeria"
            value={formdata.city}
          />
        </div>
        <div className="mb-3 form-check text-end">
          {errors.map((error, index) => (
            <div key={index} className="text-start alert alert-danger">
              {error}
            </div>
          ))}
          <SaveAndContinueButton handleForm={handleForm} isLoading={isLoading} />
        </div>
      </form>
    </div>
  );
};

export default BioData;