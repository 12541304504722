import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { useAtom } from "jotai";
import LandinPage from "./pages/LandingPage";
import ApplyPage from "./pages/ApplyPage";
import NotFound from "./pages/NotFound";
import BioData from "./components/apply/BioData/BioData";
import Communications from "./components/apply/Communications/Communications";
import Eligibility from "./components/apply/Eligibility/Eligibility";
import Employment from "./components/apply/Employment/Employment";
import Other from "./components/apply/Other/Other";
import Success from "./components/apply/Success";
import SelectionVideo from "./components/apply/selectionVideo";
import SelectionConfirmation from "./components/apply/selectionConfirmation";
import Login from "./components/apply/Login";
import FinancialAid from "./components/apply/financialAid";

import { referrerVendorAtom, isLoggedInAtom, formdataAtom } from "./lib/atom";
import ProtectedRoute from "./components/ProtectedRoute"; // Import the ProtectedRoute component

import "./App.css";
import ScrollToTop from "./scrollToTop";
import VerifyEmail from "./components/apply/VerifyEmail";

function App() {
  const location = useLocation();
  const [referralCode, setReferralCode] = useAtom(referrerVendorAtom);
  const [isLoggedIn] = useAtom(isLoggedInAtom);
  const [formdata] = useAtom(formdataAtom);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const ref = params.get("ref");
    if (ref) {
      setReferralCode(ref);
    }
  }, [location.search, setReferralCode]);

  return (
    <>
      <ScrollToTop
        path={[
          "/biodata",
          "/communications",
          "eligibility",
          "emloyment",
          "other",
        ]}
      />
      <Routes location={location} key={location.pathname}>
        <Route index element={<LandinPage />} />
        <Route path="apply" element={<ApplyPage referralCode={referralCode} />}>
          <Route index element={<Login />} />
          <Route
            path="biodata"
            element={
              <ProtectedRoute isAllowed={isLoggedIn} element={<BioData />} />
            }
          />
          <Route
            path="communications"
            element={
              <ProtectedRoute
                isAllowed={isLoggedIn}
                element={<Communications />}
              />
            }
          />
          <Route
            path="verify-email"
            element={
              <ProtectedRoute
                isAllowed={isLoggedIn}
                element={<VerifyEmail />}
              />
            }
          />
          <Route
            path="eligibility"
            element={
              <ProtectedRoute
                isAllowed={isLoggedIn && formdata.email_verified_at !== null}
                element={<Eligibility />}
              />
            }
          />
          <Route
            path="employment"
            element={
              <ProtectedRoute
                isAllowed={isLoggedIn && formdata.email_verified_at !== null}
                element={<Employment />}
              />
            }
          />
          <Route
            path="other"
            element={
              <ProtectedRoute
                isAllowed={isLoggedIn && formdata.email_verified_at !== null}
                element={<Other />}
              />
            }
          />
          <Route
            path="success"
            element={
              <ProtectedRoute
                isAllowed={isLoggedIn && formdata.email_verified_at !== null}
                element={<Success />}
              />
            }
          />
          <Route path="selection-video" element={<SelectionVideo />} />
          <Route path="selection-video" element={<SelectionVideo />} />
          <Route path="confirmation" element={<SelectionConfirmation />} />
          <Route path="financial-aid" element={<FinancialAid />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
