import { useAtom } from "jotai";
import {
  formdataAtom,
  cohortAtom,
  isUpdatingAtom,
  isLoggedInAtom,
} from "../lib/atom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSetRegistratioData } from "./useSetRegistratioData";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useResetFormData } from "./useResetFormData";

const MySwal = withReactContent(Swal);

export const useSaveEmailToDatabase = () => {
  const router = useNavigate();
  const [formdata] = useAtom(formdataAtom);
  const [, setIsUpdate] = useAtom(isUpdatingAtom);
  const { setRegistrationData } = useSetRegistratioData();
  const [, setIsLoggedIn] = useAtom(isLoggedInAtom);
  const [cohort] = useAtom(cohortAtom);
  const { resetFormdata } = useResetFormData();

  const saveEmailToDatabase = async () => {
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/get-application/${formdata.email}`;

    try {
      const res = await axios.get(apiUrl);
      if (res.data.message !== "Start new application") {
        const extractedData = res.data.data[0];
        setRegistrationData(extractedData);
        setIsUpdate(true);
      } else {
        resetFormdata();
      }

      setIsLoggedIn(true);

      router(`/apply/biodata`);
    } catch (error) {
      const msg =
        error?.response?.data?.message ||
        error.message ||
        "An unexpected error occured. Please try again";
      await MySwal.fire({
        icon: "error",
        title: "Error",
        text: msg,
      });
      throw new Error(msg);
    }
  };

  return { saveEmailToDatabase };
};
