import { useAtom } from "jotai";
import { formdataAtom } from "../lib/atom";

export const useSetRegistratioData = () => {
  const [formdata, setFormdata] = useAtom(formdataAtom);

  const setRegistrationData = (regObj) => {
    regObj = replaceNulls(regObj);
    setFormdata({
      ...formdata,
      firstname: regObj.firstname,
      middlename: regObj.middlename,
      lastname: regObj.lastname,
      gender: regObj.gender,
      country: regObj.country,
      city: regObj.city,
      email: regObj.email,
      email_verified_at: regObj.email_verified_at,
      phone: regObj.phone,
      whatsapp_phone: regObj.whatsapp_phone,
      referral_source: regObj.communications?.referral_source,
      referral_detail: regObj.communications?.referral_detail,
      communication_consent:
        regObj.communications?.communication_consent === 1 ? true : false,
      referrer: regObj.referrer,

      openness_to_tools: regObj.eligibility.openness_to_tools,
      work_disruption_feelings: regObj.eligibility.work_disruption_feelings,
      feedback_handling: regObj.eligibility.feedback_handling,
      approach_to_new_tasks: regObj.eligibility.approach_to_new_tasks,
      education_focus: regObj.eligibility.education_focus,
      highest_qualification: regObj.eligibility.highest_qualification,

      employment_status: regObj.employment.employment_status,
      unemployment_duration: regObj.employment.unemployment_duration,
      work_experience_years: regObj.employment.work_experience_years,
      service_completion_date: regObj.employment.service_completion_date,
      reason_for_leaving: regObj.employment.reason_for_leaving,
      exit_timeline: regObj.employment.exit_timeline,
      job_urgency: regObj.employment.job_urgency,

      key_challenge: regObj.other.key_challenge,
      leadership_experience: regObj.other.leadership_experience,
      distractions: regObj.other.distractions,
      bootcamp_expectations: regObj.other.bootcamp_expectations,

      anotherJobText: regObj.anotherJobText,
      lead: regObj.lead,
      participationReason: regObj.participationReason,
      distractions: regObj.distractions,
      challenging: regObj.challenging,
    });
  };

  const replaceNulls = (obj) => {
    const newObj = {};

    for (const key in obj) {
      newObj[key] = obj[key] === null ? "" : obj[key];
    }

    return newObj;
  };

  return { setRegistrationData };
};
