import React, { useEffect, useState } from "react";
import {
  formdataAtom,
  progressAtom,
  sharedErrorsAtom,
} from "../../../lib/atom";
import SaveAndContinueWithBackButton from "../../Utility/SaveAndContinueWithBackButton";
import { useAtom } from "jotai";
import TextArea from "../../Form/TextArea";
import { useLocation } from "react-router-dom";
import ModalComponent from "./InfoModal";

const Other = () => {
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [, setProgress] = useAtom(progressAtom);
  const [formdata] = useAtom(formdataAtom);
  const [sharedErrors] = useAtom(sharedErrorsAtom);

  useEffect(() => {
    setProgress(6);
  }, [location.pathname]);

  return (
    <div className="col-md-8 slide-col" id="otherSection">
      <h4
        className="text-start text-uppercase fw-bold"
        style={{ color: "black" }}
      >
        GETTING TO KNOW YOU
      </h4>
      <form>
        <div className="mb-4">
          <TextArea
            name="key_challenge"
            id="key_challenge"
            label="What do you find most challenging?"
            value={formdata.key_challenge}
          />
        </div>
        <div className="mb-4">
          <TextArea
            name="leadership_experience"
            id="leadership_experience"
            label="Have you ever led anything?"
            value={formdata.leadership_experience}
          />
        </div>
        <div className="mb-4">
          <TextArea
            name="distractions"
            id="distractions"
            label="How do you manage distraction?"
            value={formdata.distractions}
          />
        </div>
        <div className="mb-4">
          <TextArea
            name="bootcamp_expectations"
            id="bootcamp_expectations"
            label="What difference are you hoping The Employment Bootcamp will make in your life?"
            value={formdata.bootcamp_expectations}
          />
        </div>

        <div className="mb-4">
          {sharedErrors.map((error, index) => (
            <div key={index} className="alert alert-danger text-start">
              {error}
            </div>
          ))}
        </div>
      </form>

      <SaveAndContinueWithBackButton
        handleComms={() => setShowModal(true)}
        action={"Save & Continue"}
      />

      <ModalComponent showModal={showModal} setShowModal={setShowModal} />
    </div>
  );
};

export default Other;
